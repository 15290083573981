import React from "react";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import NurseProfileComponent from "~/components/Nurses/NurseProfile";

type NurseProfileProps = {
  id: string;
};

function NurseProfile({ id: nurseId }: NurseProfileProps) {
  return (
    <PrivateRoute>
      <NurseProfileComponent nurseId={nurseId} />
    </PrivateRoute>
  );
}

export default NurseProfile;
