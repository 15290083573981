import React, { useEffect, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CancelIcon from "@material-ui/icons/Cancel";
import styled from "styled-components";

import Flex from "~/components/Containers/Flex";
import { SectionHeader, SpacedPaper } from "~/components/Containers/SpacedPaper";
import Bold from "~/components/Typography/Bold";
import { medicalActionAPI, nurseAPI, receiverAPI } from "~/utils/api/v2";
import { Locale } from "~/utils/interfaces/Locale";
import { Nurse } from "~/utils/interfaces/Nurse";

const BlockWrapper = styled.div`
  padding: 8px;
  margin: 5px 10px;
  background: ${(props: { loading: any }) => {
    return props.loading ? "grey" : "whitesmoke";
  }};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  wrap: no-wrap;
  align-items: center;
`;

const SCancelIcon = styled(CancelIcon)`
  margin: 0px 5px 0px 10px;
  cursor: pointer;
`;

type Props = {
  nurseData: Nurse;
  setError: (err: any) => void;
  loading: boolean;
};

const ActionReceiverComponent = (props: Props) => {
  const [receivers, setReceivers] = useState<any[]>([]);
  const [medicalActions, setMedicalActions] = useState<any[]>([]);
  const [actionReceivers, setActionReceivers] = useState<any[]>([]);
  const [currentReceiver, setCurrentReceiver] = useState<any>({});
  const [currentMedicalAction, setCurrentMedicalAction] = useState<any>({});
  const [currentMedicalActions, setCurrentMedicalActions] = useState<any[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const reqReceivers = await receiverAPI.list({
          with_labs: true,
          country: props.nurseData?.country,
        });
        setReceivers(reqReceivers.data);
        const reqMedicalActions = await medicalActionAPI.list(props.nurseData?.country as Locale);
        setMedicalActions(reqMedicalActions.data);
        const reqActionReceivers = await nurseAPI.listActionReceivers(props.nurseData.id);
        setActionReceivers(reqActionReceivers.data);
      } catch (err) {
        console.log(err);
        props.setError(err);
      }
    };
    if (props.nurseData) {
      fetch();
    }
  }, [props.nurseData]);

  useEffect(() => {
    const addActionReceiver = async () => {
      if (currentMedicalAction.id && currentReceiver.id) {
        const data = {
          nurseId: props.nurseData.id,
          medicalActionId: currentMedicalAction.id,
          receiverId: currentReceiver.id,
        };
        const res = await nurseAPI.addActionReceiver(data);
        setActionReceivers(res.data);
        setCurrentMedicalAction({});
      }
    };
    addActionReceiver();
  }, [currentMedicalAction.id, currentReceiver.id]);

  useEffect(() => {
    if (currentReceiver && actionReceivers.length > 0) {
      setCurrentMedicalActions(
        actionReceivers
          .filter((actionReceiver) => actionReceiver.receiver.id === currentReceiver.id)
          .map((actionReceiver) => actionReceiver.medical_action),
      );
    }
  }, [currentReceiver, actionReceivers]);

  return (
    <SpacedPaper variant="outlined">
      <Flex direction="column">
        <SectionHeader>
          <Bold>Receptor - Acción Médica</Bold>
        </SectionHeader>
        <hr />
        <Flex
          direction="column"
          style={{ width: "50%" }}
        >
          <Bold>Receptor Actual</Bold>
          <Select
            value={currentReceiver.id || ""}
            onChange={(e) => {
              const receiver = receivers.find((receiver) => receiver.id === e.target.value);
              if (receiver) {
                setCurrentReceiver(receiver);
              }
            }}
          >
            {receivers.map((receiver) => (
              <MenuItem
                key={receiver.id}
                value={receiver.id}
              >
                {receiver.name || ""}
              </MenuItem>
            ))}
          </Select>
        </Flex>
        <Flex
          direction="column"
          style={{ width: "50%" }}
        >
          <Bold>Acciones Médicas Disponibles</Bold>

          <Select
            value={currentMedicalAction?.id || ""}
            onChange={(e) => {
              const medicalAction = medicalActions.find((medicalAction) => medicalAction.id === e.target.value);
              if (medicalAction) {
                setCurrentMedicalAction(medicalAction);
              }
            }}
          >
            {medicalActions
              .filter(
                (medicalAction) =>
                  !currentMedicalActions.map((medicalAction) => medicalAction.id).includes(medicalAction.id),
              )
              .map((medicalAction) => (
                <MenuItem
                  key={medicalAction.id}
                  value={medicalAction.id}
                >
                  {medicalAction.name || ""}
                </MenuItem>
              ))}
          </Select>
        </Flex>
        <Flex direction="column">
          <Bold>Acciones Médicas Asignadas</Bold>

          <Flex
            direction="row"
            style={{ width: "50%" }}
          >
            {currentMedicalActions &&
              currentMedicalActions?.map((medicalAction) => (
                <BlockWrapper
                  loading={props.loading}
                  key={medicalAction.id}
                >
                  {medicalAction.name}
                  <SCancelIcon
                    fontSize="small"
                    onClick={async () => {
                      const data = {
                        nurseId: props.nurseData.id,
                        medicalActionId: medicalAction.id,
                        receiverId: currentReceiver.id,
                      };
                      const res = await nurseAPI.deleteActionReceiver(data);
                      setActionReceivers(res.data);
                      setCurrentMedicalAction({});
                    }}
                  />
                </BlockWrapper>
              ))}
          </Flex>
        </Flex>
      </Flex>
    </SpacedPaper>
  );
};

export default ActionReceiverComponent;
